<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;margin-right:10px;" />
						 <a-tree-select
						         v-model="orgId"
						         style="width: 200px"
						         :tree-data="treeDataList"
						         :replace-fields="treeReplaceFields"
						         :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
						         :placeholder="l('please_select_organization_structure')"
						 		:dropdownMatchSelectWidth="false"
						         allow-clear
						         tree-default-expand-all
								 @change="treeRepChange"
						 >
						 </a-tree-select>
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

					</div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		ReportServiceProxy,LayoutOrgServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "audit-task-report",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
			this.getData();
			this.getTreeData();
		},
		data() {
			return {
				zh_CN,
				isEdit: false,
				isLook: false,
				isLoading: true, //加载中
				startTime: undefined,
				endTime: undefined,
				orgId: undefined,
				month: [],
				nowTime: [],
				
				chartData: [],
				data: undefined,
				treeDataList: [],
				treeReplaceFields: {
				    title: 'levelNameAndName',
				    key: 'id',
				    value: 'id'
				},
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.l('Audit_task_completion_rate'),
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// data: [this.l('FindNum'), this.l('CloseNum'), this.l('CloseRate')],
						top: '97%',
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '',
						data: this.month,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('Plan_Count'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
							},
						},
						{
							type: 'value',
							name: this.l('FinishRate') + '(%)',
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}%',
							},
							splitLine:{
							    show:true,
							    lineStyle:{
							        type:'dashed'
							    }
							}
						},
					],
					series: this.chartData,
				}
			}
		},
		methods: {
			moment,
			function() {
				// this.yearDate = moment(new Date());
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startTime = moment(startTime);
				this.endTime = moment(endTime);
			},
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getAuditTaskHistogram(
					this.startTime,
					this.endTime,
					this.orgId
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.data = res;
					this.chartsData();
				})
			},

			chartsData() {
				let discoveriesNumber = {
					name: this.l('Plan_Count'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeNumber = {
					name: this.l('Completed_Count'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('FinishRate'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				this.month = [];
				let discoveriesdata = [];
				let closedata = [];
				let closeRatedata = [];
				for (let i = 0; i < this.data.length; i++) {
					discoveriesdata.push(this.data[i].auditTaskPlanCount);
					closedata.push(this.data[i].auditTaskCompleteCount);
					closeRatedata.push(this.data[i].completePercent);
					this.month.push(this.data[i].month)
				}
				discoveriesNumber.data = discoveriesdata;
				closeNumber.data = closedata;
				closeRate.data = closeRatedata;
				this.chartData = [];
				this.chartData.push(discoveriesNumber);
				this.chartData.push(closeNumber);
				this.chartData.push(closeRate);
				this.charts();
			},

			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				document.getElementById("mainScrapCode").style.height = "720px";
				this.myeChart.setOption(this.option);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			getTreeData() {
			    this.isLoading = true;
			    this._LayoutOrgServiceProxy.getAllData(
			    )
			        .finally(() => {
			            this.isLoading = false;
			        })
			        .then((res) => {
			            this.treeDataList = JSON.parse(JSON.stringify(res));
			        })
			},
			
			//选择日期
			DiscoverytimeChange(date, dateString) {
				this.startTime = date[0];
				this.endTime = date[1];
				this.getData();
			},
			
			//选择组织架构
			treeRepChange(key) {
				this.getData();
			},
		}
	}
</script>

<style>
</style>
